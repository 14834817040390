import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

function Square(props) {
  return (
    <button
      className={props.isHighLight ? 'square red' : 'square'}
      onClick={() => props.onClick()}
    >
      {props.value}
    </button>
  );
}

class Board extends React.Component {
  // 获取高亮
  getHighLight(arr, index) {
    let returnIndex = false
    arr.forEach((ele) => {
      if (ele === index) returnIndex = true;
    })
    return returnIndex
  }
  arrSec(parent, paIndex) {
    return (
      parent.map((ele, index) => {
        return (<Square key={index + paIndex} value={this.props.value[index + paIndex * 3]}
          onClick={() => { this.props.onClick(index + paIndex * 3) }} isHighLight={this.getHighLight(this.props.highLight, index + paIndex * 3)} />)
      })
    )
  }
  render() {
    const row = 3//行
    const column = 3//列
    const arr = Array(column).fill(Array(row).fill(null))
    return (
      <div>
        {arr.map((item, index) => {
          return (
            <div key={index} className="board-row">
              {this.arrSec(item, index)}
            </div>)
        })}
      </div>
    )
  }
}

class Game extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // 选手列表
      player: {
        'X': '张三',
        'O': '李四',
      },
      // 历史记录
      history: [{
        squares: Array(9).fill(null),
        status: 'X',  //下一个选手
        index: null,  //上次落棋
      }],
      tips: null,//提示语
    }
  }
  // 获取选手名字
  getName(status) {
    const player = this.state.player;
    for (const index in player) {
      if (status === index) return player[index]
      // console.log(status, index, player[index]);
    }
  }
  // 点击事件
  handleClick(i) {
    const winner = this.judgeWinner()
    if (winner) return
    const history = this.state.history
    const squares = history[history.length - 1].squares.slice();
    const count = history.length
    const tips = null
    let status = history[history.length - 1].status
    let index = history[history.length - 1].index
    // 一轮，填空玩法
    if (count < 10) {
      if (squares[i]) {
        this.setState({ tips: "此处已有棋子" })
        return
      }
      else {
        if (status === 'X') {
          squares[i] = status; status = 'O'
        }
        else if (status === 'O') {
          squares[i] = status; status = 'X'
        }
      }
    }
    // 二轮，覆盖玩法
    else {
      if (index === i) {
        this.setState({ tips: "不能落在上次落棋的地方" })
        return
      }
      else if (status === squares[i]) {
        this.setState({ tips: "您已在此落棋" })
        return
      }
      else {
        index = i
        if (status === 'X') {
          squares[i] = status; status = 'O'
        }
        else if (status === 'O') {
          squares[i] = status; status = 'X'
        }
      }
    }
    this.setState({ history: history.concat([{ squares: squares, status: status, index: index }]), tips: tips });
  }
  // 判断胜者
  judgeWinner() {
    const history = this.state.history
    const squares = history[history.length - 1].squares
    const count = history.length
    // 一轮，填空玩法
    if (count <= 10) {
      const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
      ];
      for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] &&
          squares[a] === squares[b] &&
          squares[a] === squares[c]) {
          return { status: squares[a], winnerArr: lines[i] };
        }
      }
      return null;
    }
    // 二轮，覆盖玩法
    else {
      const lines5 = [
        [0, 1, 2, 3, 6],
        [0, 1, 2, 4, 7],
        [0, 1, 2, 5, 8],
        [0, 1, 2, 4, 6],
        [0, 1, 2, 4, 8],
        [3, 4, 5, 0, 6],
        [3, 4, 5, 1, 7],
        [3, 4, 5, 2, 8],
        [3, 4, 5, 2, 6],
        [3, 4, 5, 0, 8],
        [6, 7, 8, 0, 3],
        [6, 7, 8, 1, 4],
        [6, 7, 8, 2, 5],
        [6, 7, 8, 0, 4],
        [6, 7, 8, 2, 4],
        [0, 3, 6, 4, 8],
        [0, 3, 6, 4, 2],
        [1, 4, 7, 0, 8],
        [1, 4, 7, 2, 6],
        [2, 5, 8, 4, 0],
        [2, 5, 8, 4, 6],
      ]
      for (let i = 0; i < lines5.length; i++) {
        const [a, b, c, d, e] = lines5[i];
        if (squares[a] &&
          squares[a] === squares[b] &&
          squares[a] === squares[c] &&
          squares[a] === squares[d] &&
          squares[a] === squares[e]) {
          return { status: squares[a], winnerArr: lines5[i] };
        }
      }
      const lines6 = [
        [0, 1, 2, 3, 4, 5],
        [0, 1, 2, 6, 7, 8],
        [3, 4, 5, 6, 7, 8],
        [0, 3, 6, 1, 4, 7],
        [0, 3, 6, 2, 5, 8],
        [1, 4, 7, 2, 5, 8],
      ];
      for (let i = 0; i < lines6.length; i++) {
        const [a, b, c, d, e, f] = lines6[i];
        if (squares[a] &&
          squares[a] === squares[b] &&
          squares[a] === squares[c] &&
          squares[a] === squares[d] &&
          squares[a] === squares[e] &&
          squares[a] === squares[f]) {
          return { status: squares[a], winnerArr: lines6[i] };
        }
      }
      return null;
    }
  }
  // 悔棋（上一次）
  jumpBack(historyIndex) {
    let history = this.state.history
    history.pop()
    this.setState({
      history: history
    });
  }
  // 新游戏
  newGame() {
    const history = [{
      squares: Array(9).fill(null),
      status: 'X',  //下一个选手
      index: null,  //上次落棋
    }]
    this.setState({ history: history })
  }

  render() {
    let winner = this.judgeWinner() //判断胜者
    if (winner) winner.status = `恭喜，${this.getName(winner.status)}选手获得胜利！`
    const history = this.state.history
    const tips = this.state.tips
    const current = history[history.length - 1];
    const count = history.length - 1

    let historyIndex
    history.forEach((ele, index) => {
      historyIndex = index - 1
    });

    return (
      <div className="game">
        <div className="game-board">
          <div className="status">Next player: {this.getName(current.status)}</div>
          <Board value={current.squares} onClick={(i) => { this.handleClick(i) }} highLight={winner ? winner.winnerArr : []} />
          <div className="status red">{winner ? winner.status : tips ? tips : ''}</div>
        </div>
        <div className="game-info">
          <div>规则：<br />
            <span className={count < 9 || (count === 9 && winner) ? 'red' : ''}>1. 常规玩法：</span>填空，完成【1对】三连即可获得胜利。<br />
            <span className={count > 9 || (count === 9 && !winner) ? 'red' : ''}>2. 覆盖玩法：</span>在九宫格全部被填满后，可以继续在对方的棋子上落棋，直至有一方完成【2对】三连，获得胜利。注：不能将棋子落在对方刚刚落下的位置。</div>
          <div className='button'>
            {winner ?
              <button onClick={() => this.newGame()}>再来一局</button>
              : ''}&nbsp;
            {count ?
              <button onClick={() => this.jumpBack(historyIndex)}>悔棋</button>
              : ''}
          </div>
        </div>
        <a href="https://beian.miit.gov.cn/" className='link' target="_blank">闽ICP备2021018596号-1</a>
      </div >
    );
  }
}

// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Game />);
